import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field";
import { db, log, serverTimestamp, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { createNameId } from "../../hr-id";
import { Chunk, Rule } from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

const clamp = (min, max, n) => {
  return Math.min(Math.max(n, min), max);
};

const chunk = (text, chunkSize = 1000) => {
  // we need to try to split first on double newlines, then if that doesn't work, on single newlines
  // then if that doesn't work, on periods, then if that doesn't work, on spaces
  const splitOnList = ["\n\n", "\n", ".", " "];
  const split = (text, splitOn) => {
    // this will split the text on the splitOn string, and then
    // recombine the individual chunks into a single string until they get to
    // the chunkSize
    const res = [];
    const splitText = text.split(splitOn);
    let chunk = "";
    for (let i = 0; i < splitText.length; i++) {
      const split = splitText[i];
      if (chunk.length + split.length > chunkSize) {
        if (chunk.trim().length) res.push(chunk.trim());
        chunk = "";
      }
      chunk += split;
    }
    if (chunk.trim().length > 0) res.push(chunk.trim());
    for (let i = 0; i < res.length; i++) {
      // if the chunk is too large, we need to split it on the next splitOn string
      if (res[i].length > chunkSize) {
        const nextSplitOn = splitOnList[splitOnList.indexOf(splitOn) + 1];
        const chunks = split(res[i], nextSplitOn);
        res.splice(i, 1, ...chunks);
      }
    }
    return res;
  };
  const chunks = split(text, splitOnList[0]);
  // now we split the text on the first splitOn string that works
  const res = chunks.map((chunk, i) => new Chunk(chunk, i));
  return res;
};

function Data() {
  useLogPageView("Text");
  const [record, setRecord] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [newRuleName, setNewRuleName] = useState("");
  const [newRuleContent, setNewRuleContent] = useState("");
  const [historyIndex, setHistoryIndex] = useState(1);
  const [urlBlob, setUrlBlob] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();
  const colPath = `projects/${activeProjectId}/${COLLECTION}/${id}/chunks`;
  const [chunks = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    2,
    "sequenceNumber",
    "asc"
  );

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
      setDisplayName(data.displayName || "");
      setDescription(data.description || "");
      setContent(data.content || "");
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  const changeName = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { displayName });
  };

  const changeDescription = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { description });
  };

  const changeContent = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    // just save the first 200,000 characters
    await updateDoc(docRef, { content: content.slice(0, 200000) });
  };

  const generateBlob = async () => {
    // get all the chunks, ordered by sequenceNumber
    const colRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "chunks"
    );
    const q = query(colRef, orderBy("sequenceNumber", "asc"));
    const querySnapshot = await getDocs(q);
    let data = "";
    querySnapshot.forEach(doc => {
      const chunk = doc.data();
      data += "\n\n" + chunk.content;
    });
    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
    const urlBlob = URL.createObjectURL(blob);
    setUrlBlob(urlBlob);
  };

  const deleteAllChunks = async () => {
    if (!activeProjectId) return;
    const colRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "chunks"
    );
    const colSnap = await getDocs(colRef);
    const batch = writeBatch(db);
    colSnap.forEach(doc => batch.delete(doc.ref));
    await batch.commit();
  };

  const saveChunks = async chunks => {
    if (!activeProjectId) return;
    const batch = writeBatch(db);
    chunks.forEach((chunk, index) => {
      if (!chunk.history) chunk.history = [];
      if (!chunk.history.length) {
        chunk.history.push({
          id: uuid(),
          content: chunk.content,
          rules: [],
        });
      }
      const docRef = doc(
        db,
        "projects",
        activeProjectId,
        COLLECTION,
        id,
        "chunks",
        chunk.id
      );
      batch.set(docRef, chunk);
    });
    await batch.commit();
  };

  const chunkText = async () => {
    // first issue the delete chunks command to clear out the old chunks
    await deleteAllChunks();
    // then chunk the text
    const chunks = chunk(content || record.content);
    // then save the chunks
    await saveChunks(chunks);
  };

  const addRule = async rule => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { rules: [...(record.rules || []), rule] });
  };

  const deleteChunk = async chunkId => {
    if (!activeProjectId) return;
    log("deleteTextChunk");
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "chunks",
      chunkId
    );
    return await deleteDoc(docRef);
  };

  const deleteRule = async ruleId => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, {
      rules: record.rules.filter(rule => rule.id !== ruleId),
    });
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, update);
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const applyRules = async () => {
    // okay this is what it's all about, we're going to create a new
    // "applyRules" document in the database, and that's going to trigger
    // a cloud function that will apply the rules to the text and create
    // a new "text" document with the results
    if (!activeProjectId) return;
    const applyRulesDoc = {
      projectId: activeProjectId,
      textId: id,
      id: uuid(),
      created: serverTimestamp(),
    };
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "apply_rules_request",
      applyRulesDoc.id
    );
    await setDoc(docRef, applyRulesDoc);
  };

  if (!record) return null;

  const renderModelChoiceArea = () => {
    const model = record?.model || "gpt-4";
    return (
      <div className="model-choice-area">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="models"
            name="models"
            value={model}
            onChange={event => {
              updateRecord({ model: event.target.value });
            }}
          >
            <FormControlLabel value="gpt-4" control={<Radio />} label="GPT-4" />
            <FormControlLabel
              value="gpt-3.5"
              control={<Radio />}
              label="GPT-3.5"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const renderNoteArea = () => {
    return (
      <div className="note-area">
        <Tf
          fullWidth
          label="Notes go here..."
          multiline
          value={record?.content?.text || ""}
          onChange={e => updateRecord({ content: { text: e.target.value } })}
        />
      </div>
    );
  };

  const renderOneChunk = (c, index) => {
    const indexA = c.history.length - historyIndex - 1;
    const indexB = c.history.length - historyIndex;
    return (
      <div className="chunk-card" key={c.id}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={c.history?.length >= 2 ? 6 : 12}
            sx={{
              display: c.history?.length < 2 ? "none" : "block",
              background:
                c.status && c.status === "IN_PROGRESS" ? "#fc9" : "transparent",
            }}
            key={"chunk-" + c.id + "-" + index + "-" + historyIndex}
          >
            <div className="faint">Version {indexA + 1}</div>
            <ReactMarkdown className="react-markdown-code-block">
              {c.history?.length ? c.history[indexA]?.content : c.content}
            </ReactMarkdown>
            <div></div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={c.history?.length < 2 ? 12 : 6}
            key={"current-chunk-" + c.id + "-" + index}
            sx={{ display: c.history?.length < 2 ? "none" : "block" }}
          >
            <div className="faint">Version {indexB + 1}</div>
            <ReactMarkdown className="react-markdown-code-block">
              {c.history?.length ? c.history[indexB]?.content : c.content}
            </ReactMarkdown>
            {historyIndex === 1 && (
              <>
                <div style={{ float: "right" }} className="faint">
                  current version
                </div>
                <div style={{ clear: "both" }} />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {c.history.length - historyIndex + 1} of {c.history?.length}
            <Button
              onClick={() =>
                setHistoryIndex(hi => clamp(0, c.history.length, hi + 1))
              }
            >
              Previous
            </Button>
            <Button
              onClick={() =>
                setHistoryIndex(hi => {
                  const hIndex = clamp(1, c.history.length, hi - 1);
                  return hIndex;
                })
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const TextChunks = ({ chunks = [] }) => {
    if (!chunks.length) return null;
    if (!chunks[0]?.history?.length && chunks[0]?.content) {
      // this is a new chunk, so we need to create a history
      chunks[0].history = [
        {
          content: chunks[0].content,
          created: serverTimestamp(),
          rules: [],
        },
      ];
    }
    console.log(chunks[0]?.history);
    const index = chunks[0].history.length - historyIndex;
    const rules = chunks[0]?.history[index]?.rules || [];
    const rulesMessage = rules.map((rule, index) => (
      <div key={rule.id} className="rule-card">
        {rule.content}
      </div>
    ));
    return (
      <div className="source-content-area">
        {!!rulesMessage.length && (
          <div className="rules-exposition">
            <h3>Rules used for this edit</h3>
            {rulesMessage}
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="before-and-after">
            BEFORE
          </Grid>
          <Grid item xs={12} sm={6} className="before-and-after">
            AFTER
          </Grid>
        </Grid>
        {chunks.map(renderOneChunk)}
        {hasMore ? (
          <Button onClick={loadMore} disabled={!hasMore}>
            Load more
          </Button>
        ) : (
          <p>All loaded</p>
        )}
      </div>
    );
  };

  const renderContentArea = () => {
    if (!chunks.length) {
      return (
        <div>
          <Button
            variant="contained"
            onClick={chunkText}
            sx={{ marginTop: "12px" }}
          >
            Chunk Text
          </Button>
          <h3>Change Content</h3>
          {content.length} characters (only the first 200,000 will be saved)
          <TextField
            fullWidth
            multiline
            value={content || ""}
            onChange={e => {
              console.log(e.target.value);
              setContent(e.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={changeContent}
            sx={{ marginTop: "12px", background: "#555" }}
          >
            Change Content
          </Button>
        </div>
      );
    }
    let chunkies = [];
    if (chunks.length) {
      chunkies = <TextChunks chunks={chunks} />;
    }
    return (
      <div className="content-area">
        {record?.rules?.length && (
          <Button
            onClick={() => {
              applyRules();
            }}
          >
            Apply Rules
          </Button>
        )}
        {chunkies}
      </div>
    );
  };

  const renderSummaryStats = () => {
    if (!record.content?.text) return null;
    const text = record.content.text;
    const sentences = text.split(".").length;
    const paragraphs = text.split("\n\n").length;
    const words = text.split(" ").length;
    const letters = record.content?.text.length;
    const averageLettersPerParagraph = Math.round(letters / paragraphs);
    const costPertoken = 0.0000004;
    const cost = costPertoken * letters;
    return (
      <div className="summary-stats">
        <span>Letters: {letters}</span>
        <span>Words: {words}</span>
        <span>Sentences: {sentences}</span>
        <span>Paragraphs: {paragraphs}</span>
        <span>Average letters per paragraph: {averageLettersPerParagraph}</span>
        <span>Approximate Cost to calculate embeddings: {cost}</span>
      </div>
    );
  };

  const renderRulesArea = () => {
    const rules = record.rules || [];
    let rulies = [];
    if (rules.length) {
      rulies = rules.map(rule => {
        return (
          <div className="rule-card" key={rule.id}>
            <div className="rule-card-buttons-area">
              <IconButton
                onClick={() => {
                  deleteRule(rule.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  // add rule to rules collection
                  const docRef = doc(
                    db,
                    "projects",
                    activeProjectId,
                    "rules",
                    rule.id
                  );
                  setDoc(docRef, rule, { merge: true });
                  // and also set favorite to true
                  const docRef2 = doc(
                    db,
                    "projects",
                    activeProjectId,
                    COLLECTION,
                    record.id
                  );
                  const rules = record.rules || [];
                  const targetRule = rules.find(r => r.id === rule.id);
                  targetRule.favorite = true;
                  if (!rule.displayName)
                    targetRule.displayName = createNameId();
                  setDoc(docRef2, record);
                }}
              >
                {rule?.favorite ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            </div>
            <h3 className="faint">{rule.displayName}</h3>
            <p>{rule.content}</p>
          </div>
        );
      });
    }
    return (
      <>
        <h3>Rules for next edit</h3>
        {rulies}
        <div>
          <h3>Add a New Rule</h3>
          <TextField
            fullWidth
            label="Rule Name"
            value={newRuleName}
            onChange={e => setNewRuleName(e.target.value)}
          />
          <TextField
            fullWidth
            label="Rule Content"
            value={newRuleContent}
            onChange={e => setNewRuleContent(e.target.value)}
          />
          <Button
            onClick={() => {
              const rule = Rule();
              rule.displayName = newRuleName;
              rule.content = newRuleContent;
              addRule(rule);
              setNewRuleName("");
              setNewRuleContent("");
            }}
          >
            Add Rule
          </Button>
        </div>
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} xs={12}>
        <div>
          <h1>{record.displayName}</h1>
          <p>{record.description || ""}</p>
          {renderRulesArea()}
          {renderModelChoiceArea()}
          <h3>Edit Name</h3>
          <TextField
            fullWidth
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={changeName}
            sx={{ marginTop: "12px", background: "#555" }}
          >
            Change Name
          </Button>
          <h3>Change Description</h3>
          <TextField
            fullWidth
            multiline
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={changeDescription}
            sx={{ marginTop: "12px", background: "#555" }}
          >
            Change Description
          </Button>
        </div>
      </Grid>
      <Grid item lg={9} xs={12}>
        {renderContentArea()}
      </Grid>
      <div style={{ textAlign: "right", width: "100%" }}>
        {urlBlob ? (
          <a href={urlBlob} download={`${record.displayName}.txt`}>
            Download {record.displayName} as a text file
          </a>
        ) : (
          <Button onClick={generateBlob}>
            Generate Downloadable Full Text of Current Version
          </Button>
        )}
      </div>
      <Grid item xs={12}>
        <Card variant="outlined" className="padded DeletionArea">
          <h3> Danger Zone</h3>
          <Button variant="contained" color="secondary" onClick={deleteRecord}>
            Delete {record.displayName}
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
