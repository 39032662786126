import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Rule } from "../../models";
import "./style.css";

const COLLECTION = "rules";
const COLLECTION_DISPLAY_NAME = "Rules";

function Data() {
  useLogPageView("Rules");
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const colPath = `projects/${activeProjectId}/${COLLECTION}`;
  const [records = [], loadMore, hasMore] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );

  const createNewRule = async () => {
    if (!activeProjectId) return;
    const rec = Rule();
    await setDoc(doc(db, "projects", activeProjectId, COLLECTION, rec.id), rec);
    history.push(`/${COLLECTION}/${rec.id}`);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Button
        id="basic-button"
        onClick={() => {
          createNewRule();
        }}
        sx={{ float: "right" }}
        variant="contained"
      >
        New Rule
      </Button>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <p>
        Rules are applied to Texts to change them. Only Rules you've added to
        your favorites will be displayed here.{" "}
      </p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              let { id, displayName, content = "", lastUpdated } = record;
              content = content.substring(0, 100);
              if (record?.content?.length > 100) content += "...";
              const path = `/${COLLECTION}/${id}`;
              return (
                <TableRow
                  key={id}
                  className="animated-fade-in-from-left"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "--delay": `${index * 0.1}s`,
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {content}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(lastUpdated.seconds * 1000).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore ? (
        <Button onClick={loadMore} disabled={!hasMore}>
          Load more
        </Button>
      ) : (
        <p>All loaded</p>
      )}
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
