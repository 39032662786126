import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Sigil from "../../components/canvas";
import { CharacterImage } from "../../components/character-image/";
import MinorFrame from "../../components/minor-frame/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Chat } from "../../models";
import "./style.css";

const COLLECTION = "chats";
const COLLECTION_DISPLAY_NAME = "Chats";

function Data() {
  useLogPageView("Chats");
  const { activeProjectId = "" } = useEphemera();
  const colPath = "projects/" + activeProjectId + "/" + COLLECTION;
  const [records, loadMoreChats, hasMoreChats] = useFirestorePagination(
    colPath,
    12,
    "lastUpdated"
  );
  const [characters, loadMoreCharacters, hasMoreCharacters] =
    useFirestorePagination(
      "projects/" + activeProjectId + "/characters",
      6,
      "lastUpdated"
    );
  const history = useHistory();

  const createChat = async character => {
    if (!activeProjectId) return;
    log("createChat");
    const chat = Chat();
    chat.characterId = character.id;
    chat.characters = [character.id];
    await setDoc(
      doc(db, "projects", activeProjectId, COLLECTION, chat.id),
      chat
    );
    history.push("/" + COLLECTION + "/" + chat.id);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <h2>{COLLECTION_DISPLAY_NAME}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Chat Name</TableCell>
                  <TableCell>With</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record, index) => {
                  const { id, displayName, characterId } = record;
                  let character = {};
                  let links = [];
                  if (characterId) {
                    character = characters.find(c => c.id === characterId);
                  }
                  if (record.characters && record.characters.length > 0) {
                    links = record.characters.map(c => {
                      const character = characters.find(
                        character => character.id === c
                      );
                      const path = "/characters/" + c;
                      return (
                        <span
                          style={{ paddingRight: "8px" }}
                          key={"micro-link-" + c}
                        >
                          <Link key={c} to={path}>
                            {character?.displayName}
                          </Link>
                        </span>
                      );
                    });
                  }
                  const path = "/" + COLLECTION + "/" + id;
                  const characterPath = "/characters/" + characterId;
                  // sometimes we won't have all the characters loaded
                  const andMore = record.characters?.reduce((acc, c) => {
                    if (!characters.find(character => character.id === c)) {
                      return acc + 1;
                    }
                    return acc;
                  }, 0);

                  let andMoreMessage = andMore
                    ? "and " + andMore + " more"
                    : "";
                  if (andMore >= record.characters?.length) {
                    andMoreMessage =
                      "With " +
                      record.characters?.length +
                      " Character" +
                      (record.characters?.length > 1 ? "s" : "");
                  }
                  return (
                    <TableRow
                      key={id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "--delay": `${index * 0.12}s`,
                      }}
                      className="animated-fade-in-from-left"
                    >
                      <TableCell scope="row">
                        <Link to={path}> {displayName} </Link>
                      </TableCell>
                      <TableCell scope="row" sx={{ textAlign: "left" }}>
                        {links.length ? (
                          links
                        ) : (
                          <Link to={characterPath}>
                            {character?.displayName}{" "}
                          </Link>
                        )}
                        {andMoreMessage}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {hasMoreChats && (
            <Button onClick={() => loadMoreChats()}>Load More Chats</Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {characters.map((character, index) => {
            if (!character) return null;
            const { id, displayName, iconSeed, iconSeedToken = {} } = character;
            let truncatedDescription = character.description.substring(0, 30);
            if (character.description.length > 30)
              truncatedDescription += "...";
            const path = "/characters/" + id;
            return (
              <div
                className="character-card animated-fade-in-from-bottom"
                key={id}
                style={{
                  "--delay": `${index * 0.12}s`,
                }}
              >
                <Link to={path}>
                  {character.photoId ? (
                    <div
                      style={{
                        display: "inline-block",
                        margin: "-20px -20px 0 -20px",
                      }}
                    >
                      <CharacterImage
                        imageId={character.photoId}
                        dimensions={256}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        margin: "-20px -20px 0 -20px",
                      }}
                    >
                      <Sigil
                        seed={iconSeedToken?.seed || iconSeed || id}
                        gen={iconSeedToken?.gen || "xenophon"}
                        style={{ width: 256 }}
                      />
                    </div>
                  )}
                </Link>
                <div style={{ marginTop: 12 }}>
                  <Link to={path}> {displayName} </Link>
                </div>
                <p className="faint" style={{ height: 20, marginTop: 0 }}>
                  {truncatedDescription}
                </p>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => createChat(character)}
                >
                  Start a new Chat
                </Button>
              </div>
            );
          })}
          {hasMoreCharacters && (
            <Button onClick={() => loadMoreCharacters()}>
              Load More Characters
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
