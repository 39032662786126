import { Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { goodNames, createNameId } from "./hr-id";

const HUMAN_ID = uuid();
const BOT_ID = uuid();

const LOOKUP_REQUEST_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

//const PENDING = "PENDING";
//const SUCCESS = "SUCCESS";
//const ERROR = "ERROR";
const INCOMPLETE = "INCOMPLETE";
const READY = "READY";
const COMPLETE = "COMPLETE";
const IN_PROGRESS = "IN_PROGRESS";

export function Message(text = "") {
  return {
    id: uuid(),
    author: {
      id: HUMAN_ID,
      displayName: "",
      bot: false,
    },
    text,
    created: Timestamp.now(),
    creditCost: 0,
    objectiveScore: 0,
    duplication: false,
  };
}

function HumanMessage(text) {
  const message = Message();
  message.author.id = HUMAN_ID;
  message.author.displayName = "Jane McPerson";
  message.text = text;
  return message;
}

function BotMessage(text) {
  const message = Message();
  message.author.id = BOT_ID;
  message.author.displayName = "Penrose";
  message.author.bot = true;
  message.text = text;
  return message;
}

export function StageDirection(text) {
  return {
    id: uuid(),
    text,
  };
}

const Sd = StageDirection;

export const defaultStopSequence = "[[END OF BOT MESSAGE]]";

export const defaultStudioData = {
  tags: [],
  joinToken: ", ",
  characterTags: [],
};

export const defaultData = {
  messages: [
    HumanMessage("Afternoon Penrose."),
    BotMessage("Hello Jane, how can I help you today?"),
    HumanMessage("what do you know about Quaker philosophy?"),
    BotMessage(
      `I'm familiar with Quaker philosophy, which is based on the belief that there is something of God in everyone. Quakers strive to live in peace and harmony with each other and the world around them. They also believe in the power of silent worship and meditation.`
    ),
    HumanMessage("Thanks Penrose."),
  ],
  chat: {
    stageDirections: [
      Sd(
        `In the context of this conversation, Penrose is focused on communicating the universality of geometry and ethics.`
      ),
    ],
  },
  character: {
    displayName: "Penrose",
    stageDirections: [
      Sd(
        `Penrose is a gentle AI chatbot with a special interest in Geometry and consciousness.`
      ),
    ],
  },
  stopSequence: "[[END OF BOT MESSAGE]]",
};

export const defaultTemplateString = `SPECIAL INSTRUCTIONS:

  {{#each character.stageDirections}}
    {{{text}}}

  {{/each}}
 {{#each chat.stageDirections}}
    {{{text}}}

  {{/each}}

  CONVERSATION: 
  {{#each messages}}
    {{#if author.bot}}
      {{author.displayName}}:
      {{{text}}}
      {{../stopSequence}}
    {{else}}
      {{author.displayName}}:
      {{{text}}}
    {{/if}}

  {{/each}}
  {{character.displayName}}:

`;

export const defaultStudioTemplateString = `
{{#each characterTags as |tag|}}
{{tag}}{{../joinToken}}
{{/each}}
{{#each tags as |tag|}}
{{tag}}{{#unless @last}}{{../joinToken}}{{/unless}}
{{/each}}
`;

const pick = arr => arr[(Math.random() * arr.length) | 0];

const popularMaleNames = [
  "James",
  "John",
  "Robert",
  "Michael",
  "William",
  "David",
  "Richard",
  "Charles",
  "Joseph",
  "Thomas",
  "Christopher",
  "Daniel",
  "Paul",
  "Mark",
  "Donald",
  "George",
  "Kenneth",
  "Steven",
  "Edward",
  "Brian",
  "Ronald",
  "Anthony",
  "Kevin",
  "Jason",
  "Matthew",
  "Gary",
  "Timothy",
  "Jose",
  "Larry",
  "Jeffrey",
  "Frank",
  "Scott",
  "Eric",
  "Stephen",
  "Andrew",
  "Raymond",
  "Gregory",
  "Joshua",
  "Jerry",
  "Dennis",
  "Walter",
  "Patrick",
  "Peter",
  "Harold",
  "Douglas",
  "Henry",
  "Carl",
  "Arthur",
  "Ryan",
  "Roger",
  "Joe",
  "Juan",
  "Jack",
  "Albert",
  "Jonathan",
  "Justin",
  "Terry",
  "Gerald",
  "Keith",
  "Samuel",
  "Willie",
  "Ralph",
];

const popularFemaleNames = [
  "Mary",
  "Patricia",
  "Jennifer",
  "Linda",
  "Elizabeth",
  "Barbara",
  "Susan",
  "Jessica",
  "Sarah",
  "Karen",
  "Nancy",
  "Lisa",
  "Margaret",
  "Betty",
  "Sandra",
  "Ashley",
  "Dorothy",
  "Kimberly",
  "Emily",
  "Donna",
  "Michelle",
  "Carol",
  "Amanda",
  "Melissa",
  "Deborah",
  "Stephanie",
  "Rebecca",
  "Laura",
  "Sharon",
  "Cynthia",
  "Kathleen",
  "Helen",
  "Amy",
  "Shirley",
  "Angela",
  "Anna",
  "Brenda",
  "Pamela",
  "Nicole",
  "Ruth",
  "Katherine",
  "Samantha",
  "Christine",
  "Emma",
  "Catherine",
  "Debra",
  "Virginia",
  "Rachel",
  "Carolyn",
];

const capitalize = str => str[0].toUpperCase() + str.slice(1);

export const RandomName = () => {
  /*
  const consonants = "bcdfghjklmnpqrstvwxz".split("");
  const vowels = "aeiou".split("");
  const syllable = () => {
    const c = pick(consonants);
    const v = pick(vowels);
    return c + v;
  };
  const syllables = [];
  for (let i = 0; i < Math.random() * 3 + 1; i++) {
    syllables.push(syllable());
  }
  let name = syllables.join("");
  name = name[0].toUpperCase() + name.slice(1);
  */
  let gn = pick(goodNames);
  gn = capitalize(gn);
  return pick([pick(popularMaleNames), pick(popularFemaleNames)]) + " " + gn;
};

export function IconSeedToken() {
  const id = uuid();
  return {
    id,
    seed: id,
    gen: "xenophon",
  };
}

export const Character = () => {
  const character = {
    id: uuid(),
    displayName: RandomName(),
    lastUpdated: Timestamp.now(),
    created: Timestamp.now(),
    description: "",
    stageDirections: [],
    salutation: "Hello",
    photoId: null,
    sourceIds: [],
    serviceIds: [],
    templateId: null,
    iconSeedToken: IconSeedToken(),
    memoryFormationEnabled: false,
    model: "gpt-3.5-turbo",
  };
  return character;
};

export const Tag = (text = "new tag") => {
  return {
    id: uuid(),
    text,
  };
};

export function Studio() {
  const id = uuid();
  const activeCharacterTags = [
    Tag("gorgeous"),
    Tag("portrait"),
    Tag("artstation"),
    Tag("studio lighting"),
    Tag("face closeup"),
    Tag("extreme detail"),
    Tag("63 year old black man"),
    Tag("sci-fi"),
  ];
  const inactiveCharacterTags = [];
  const exampleData = defaultStudioData;
  exampleData.tags = activeCharacterTags.map(tag => tag.text);
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: defaultStudioTemplateString,
    exampleData,
    joinToken: ", ",
    activeCharacterTags,
    inactiveCharacterTags,
    lastUpdated: Timestamp.now(),
  };
}

export function Template() {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: defaultTemplateString,
    exampleData: defaultData,
    stopSequence: defaultStopSequence,
    lastUpdated: Timestamp.now(),
  };
}

export function LookupRequest(url, sourceId) {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    type: "webpage",
    status: LOOKUP_REQUEST_STATUS.PENDING,
    url: url || "",
    sourceId: sourceId || "",
    lastUpdated: Timestamp.now(),
  };
}

export function Source() {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    type: "WEBPAGE", // NOTE, VIDEO, AUDIO, PDF, etc
    content: {},
    url: "",
    locked: false,
    lastUpdated: Timestamp.now(),
  };
}

export function TextDoc() {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    model: "gpt-4",
  };
}

export function TextDocCunk() {
  const id = uuid();
  return {
    id,
    parentTextDocId: "",
    sequenceNumber: 0,
    displayName: createNameId(),
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    content: "",
    rules: [],
  };
}

export function Rule(content = "") {
  const id = uuid();
  return {
    id,
    displayName: createNameId(),
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    content,
    favorite: false,
  };
}

export function Chunk(content = "", sequenceNumber = 0) {
  const id = uuid();
  return {
    id,
    sequenceNumber,
    displayName: createNameId(),
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    content,
    history: [],
    mostRecentPrompt: "",
    status: COMPLETE,
  };
}

export const Service = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    lastUpdated: Timestamp.now(),
    lastCalled: null,
    numberOfCalls: 0,
    outboundTemplateId: "",
    inboundTemplateId: "",
    exampleRequestJSON: {},
    exampleResponseJSON: {},
    exampleRequest: "",
    exampleResponse: "",
    url: "",
    httpMethod: "POST", // GET
    basicAuth: {
      username: "",
      password: "",
    },
    headers: [],
  };
};

export const Header = () => {
  return {
    id: uuid(),
    key: "",
    value: "",
  };
};

export const ClientAccessToken = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    lastUpdated: Timestamp.now(),
    lastCalled: null,
    numberOfCalls: 0,
    secret: uuid(), // dumb client secret for now, we can do better later
  };
};

export const Turn = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
    templateId: "",
    templateDisplayName: "",
    templateString: "",
    data: {},
    chatId: "",
    chatDisplayName: "",
    prompt: "",
    response: null,
  };
};

export const ImageRequest = prompt => {
  return {
    id: uuid(),
    status: LOOKUP_REQUEST_STATUS.PENDING,
    displayName: createNameId(),
    created: Timestamp.now(),
    templateString: "",
    data: {},
    studioId: "",
    studioDisplayName: "",
    projectId: "",
    prompt: prompt || "",
    engineId: "stable-diffusion-512-v2-1",
    response: null,
  };
};

export const CompletionRequest = prompt => {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
    templateId: "",
    templateDisplayName: "",
    templateString: "",
    turnId: "",
    data: {},
    chatId: "",
    chatDisplayName: "",
    prompt: prompt || "",
    response: null,
  };
};

export const ApiTest = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    created: Timestamp.now(),
    serviceId: "",
    serviceDisplayName: "",
  };
};

export const ServiceTest = () => {
  return {
    id: uuid(),
    displayName: createNameId(),
    description: "",
    created: Timestamp.now(),
    serviceId: "",
    serviceDisplayName: "",
  };
};

export function Project() {
  return {
    id: uuid(),
    displayName: createNameId(),
    created: null,
  };
}

export const Chat = () => {
  const id = uuid();
  const chat = {
    id,
    displayName: createNameId(),
    sequenceNumber: 0,
    objective: "",
    planId: null,
    currentTaskId: null,
    groupChat: false,
    characterId: null,
    messages: [],
    characters: [],
    lastUpdated: Timestamp.now(),
    stageDirections: [],
  };
  return chat;
};

export const Package = () => {
  const id = uuid();
  const pack = {
    id,
    displayName: createNameId(),
    description: "",
    version: 1,
    created: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    sourceProjectId: "",
    characterIds: [],
    templateIds: [],
    studioIds: [],
    sourceIds: [],
    serviceIds: [],
    planIds: [],
    chatIds: [],
  };
  return pack;
};

export const UpdatePackageRequest = packageId => {
  const pack = {
    id: uuid(),
    displayName: createNameId(),
    packageId,
    created: Timestamp.now(),
  };
  return pack;
};

export const InstallPackageRequest = packageId => {
  const rec = {
    id: uuid(),
    displayName: createNameId(),
    packageId,
    created: Timestamp.now(),
  };
  return rec;
};

export const SaveEmbeddingsRequest = () => {
  const rec = {
    id: uuid(),
    displayName: createNameId(),
    created: Timestamp.now(),
  };
  return rec;
};

export const Elicitation = characterId => {
  return {
    id: uuid(),
    characterId,
    userId: null,
    displayName: createNameId(),
    created: Timestamp.now(),
  };
};

export const Plan = () => {
  return {
    id: uuid(),
    description: "",
    displayName: createNameId(),
    // change this from an array to a single root node called objective
    tasks: [],
    status: READY,
    created: Timestamp.now(),
  };
};

export const Task = () => {
  return {
    id: uuid(),
    created: Timestamp.now(),
    description: "",
    status: INCOMPLETE,
    result: null,
    subtasks: [],
  };
};
