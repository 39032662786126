import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Chunk } from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

function Data() {
  useLogPageView("Rule");
  const [record, setRecord] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [content, setContent] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const { activeProjectId = "" } = useEphemera();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
      setDisplayName(data.displayName || "");
      setContent(data.content || "");
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  const changeName = async () => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { displayName });
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, update);
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  if (!record) return null;

  return (
    <div>
      <div>
        <h1>{record.displayName}</h1>
        <p>{record.description || ""}</p>
        <h3>Edit Name</h3>
        <TextField
          fullWidth
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={changeName}
          sx={{ marginTop: "12px" }}
        >
          Change Name
        </Button>
        <h3>Change Content</h3>
        <TextField
          fullWidth
          multiline
          value={content}
          onChange={e => setContent(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => {
            updateRecord({ content });
          }}
          sx={{ marginTop: "12px" }}
        >
          Change Content
        </Button>
      </div>
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="secondary" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </div>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
