import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  Timestamp,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { v4 as uuid } from "uuid";
import { firebaseConfig } from "./config";
import { useEffect } from "react";

const app = initializeApp(firebaseConfig);

export const serverTimestamp = Timestamp.now;

const analytics = getAnalytics(app);
export const log = (...args) => logEvent(analytics, ...args);
export function useLogPageView(title = "") {
  useEffect(() => {
    if (!title) return;
    log("page_view", {
      page_title: title,
      page_location: window.location.href,
    });
  }, [title]);
  return null;
}

export const db = getFirestore();

export const auth = getAuth();
const provider = new GoogleAuthProvider();

export const createUser = async (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const user = userCredential.user;
      return user;
      // ...
    })
    .catch(console.error);
};

export const signIn = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const user = userCredential.user;
      return user;
      // ...
    })
    .catch(console.error);
};

export const authStateChanged = async () => {
  return onAuthStateChanged(auth, user => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      //const uid = user.uid;
      return user;
      // ...
    } else {
      // User is signed out
      // ...
      return null;
    }
  });
};

const filterUserData = user => {
  let result = {};
  result.uid = user.uid;
  result.email = user.email;
  result.displayName = user.displayName;
  result.photoURL = user.photoURL;
  result.emailVerified = user.emailVerified;
  result.phoneNumber = user.phoneNumber;
  result.pineconeDbIndex = uuid();
  result.created = Timestamp.now();
  return result;
};

export const signInWithGooglePopup = async () => {
  console.log("signInWithGooglePopup");
  return signInWithPopup(auth, provider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      return user;
      // ...
    })
    .then(user => {
      // check if user exists in db
      log("userSignInWithGoogle");
      const userDoc = filterUserData(user);
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      return getDocs(q)
        .then(querySnapshot => {
          console.count("getDocs");
          console.log("querySnapshot", querySnapshot);
          if (querySnapshot.empty) {
            // user does not exist, create user
            // add the doc to the database
            console.log("user does not exist, creating user");
            setDoc(doc(db, "users", userDoc.uid), userDoc);
          } else {
            // user exists, update user
            console.log("user exists, updating user");
            updateDoc(doc(db, "users", userDoc.uid), userDoc);
          }
          return user;
        })
        .catch(e => {
          // if this errors out, it's because we don't have permission to read
          // a user that doesn't exist
          console.log("user does not exist, creating user");
          console.error(e);
          return null;
          //return setDoc(doc(db, "users", userDoc.uid), userDoc);
        });
    })
    .catch(error => {
      // Handle Errors here.
      //const errorCode = error.code;
      //const errorMessage = error.message;
      // The email of the user's account used.
      //const email = error.email;
      // The AuthCredential type that was used.
      log("userSignInWithGoogleError");
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error(error);
      return credential;
      // ...
    });
};
