import DeleteIcon from "@mui/icons-material/Delete";
import Sigil from "../../components/canvas";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactMarkdown from "react-markdown";
import { Link, useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { CharacterImage } from "../../components/character-image";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field";
import { auth, db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { Elicitation, Message } from "../../models";
import { fromNow } from "../../utils";
import { COLLECTION, COLLECTION_DISPLAY_NAME } from "./constants";
import "./style.css";

const drawerWidth = 300;

const pick = arr => arr[Math.floor(Math.random() * arr.length)];

const find = (s, fn) => {
  // a recursive function to find a value in a nested object
  if (fn(s)) return s;
  if (!s.subtasks) return null;
  for (let i = 0; i < s.subtasks.length; i++) {
    const res = find(s.subtasks[i], fn);
    if (res) return res;
  }
  return null;
};

/*
const findWide = (s, fn) => {
  // a breadth first search to find a value in a nested object
  const queue = [s];
  while (queue.length) {
    const curr = queue.shift();
    if (fn(curr)) return curr;
    if (curr.subtasks) queue.push(...curr.subtasks);
  }
  return null;
};
*/

const findAll = (s, fn) => {
  // a recursive function that finds all values in a nested object
  const res = [];
  if (fn(s)) res.push(s);
  if (!s.subtasks) return res;
  for (let i = 0; i < s.subtasks.length; i++) {
    res.push(...findAll(s.subtasks[i], fn));
  }
  return res;
};

const BouncingElipsis = ({ delay = 0 }) => {
  // we want ot wait for "delay" milliseconds before showing the dots
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!show) return null;

  return (
    <Card
      sx={{ p: 1, m: 1 }}
      style={{ background: "#f0f0f0", fontSize: "2em", opacity: 1 }}
      className="pseudo-message-card "
    >
      <div className="jumping-dots">
        <span className="dot-1">.</span>
        <span className="dot-2">.</span>
        <span className="dot-3">.</span>
      </div>
    </Card>
  );
};

const CharacterImageContainer = ({ imageId }) => {
  const style = {
    textAlign: "center",
    padding: 0,
    marginTop: 12,
    display: "inline-block",
    float: "left",
    marginRight: 24,
  };
  return (
    <div style={style}>
      <CharacterImage imageId={imageId} dimensions={256} />
    </div>
  );
};

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StageDirection = (text = "") => {
  return {
    id: uuid(),
    text,
    created: new Date(),
  };
};

function NewMessageCard({ createMessage }) {
  const [message, setMessage] = useState("");
  const messageInputRef = useRef(null);
  return (
    <Card sx={{ p: 2, m: 2 }}>
      <TextField
        fullWidth
        multiline
        rows={4}
        inputRef={messageInputRef}
        placeholder="Add a message..."
        value={message}
        onChange={e => {
          setMessage(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          createMessage(message);
          setMessage("");
          messageInputRef.current.focus();
        }}
      >
        Submit
      </Button>
    </Card>
  );
}

function NewMessageBottomBar({
  messages = [],
  createMessage,
  creditsRemaining = 0,
}) {
  const [message, setMessage] = useState("");
  const messageInputRef = useRef(null);
  const bottomBarRef = useRef(null);
  // flex container sticks to bottom of parent div
  const style = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#ffffff",
  };
  // if credits are below the threshold, make the bottom bar redish
  const renderSubmitBar = () => {
    if (message.length === 0) return null;
    if (!creditsRemaining) return null;
    if (creditsRemaining <= 0) return null;
    return (
      <div style={{ textAlign: "right", padding: 12 }}>
        <Button
          onClick={() => {
            createMessage(message);
            setMessage("");
            messageInputRef.current.focus();
          }}
        >
          Send
        </Button>
      </div>
    );
  };

  if (creditsRemaining <= 0) {
    return (
      <div style={style} ref={bottomBarRef}>
        <p>
          You can <Link to="/settings">buy more credits</Link> to message more.
        </p>
      </div>
    );
  }

  return (
    <div style={style} ref={bottomBarRef}>
      <div style={{ padding: 8 }}>
        <TextField
          fullWidth
          variant="standard"
          autoComplete="off"
          placeholder="Add a message..."
          value={message}
          inputRef={messageInputRef}
          onFocus={() => {
            bottomBarRef.current.scrollTop = bottomBarRef.current.scrollHeight;
          }}
          onChange={e => {
            bottomBarRef.current.scrollTop = bottomBarRef.current.scrollHeight;
            setMessage(e.target.value);
          }}
        />
      </div>
      {renderSubmitBar()}
    </div>
  );
}

function truncatedText(text, length) {
  if (text.length <= length) return text;
  return text.substring(0, length) + "...";
}

function MetaMessageCard({ metaMessage = {}, style = {} }) {
  const [expanded, setExpanded] = useState(false);
  const textAlign = "right";
  const margin = 2;
  return (
    <div
      style={{ ...style, textAlign, margin }}
      className="animated-fade-in-from-right"
    >
      <span
        className="meta-message-card-title"
        onClick={() => setExpanded(exp => !exp)}
      >
        {truncatedText(metaMessage.text, 40)}
      </span>
      <div
        style={{
          display: expanded ? "block" : "none",
          textAlign: "left",
          padding: 12,
          border: "1px solid #ccc",
        }}
      >
        <p className=" faint">{fromNow(metaMessage.created)}</p>
        <ReactMarkdown className="react-markdown-code-block">
          {metaMessage.text}
        </ReactMarkdown>
        <p className="faint">Credit Cost: {metaMessage.creditCost || 0}</p>
      </div>
    </div>
  );
}

function MessageCard({
  message,
  updateMessage = () => {},
  deleteMessage = null,
  character = {},
  acceptAnswerForPlan = () => {},
  style = {},
}) {
  const backgroundColor = message.author.bot ? "#f0f0f0" : "#ffffff";
  const [expanded, setExpanded] = useState(false);
  const authorStyle = { fontWeight: "bold" };
  // make a few chips at the bottom of the card that only fade into view
  // when the card is hovered over
  const d = 32;
  let img = <CharacterImage imageId={character?.photoId} dimensions={d} />;
  if (!character.photoId) {
    img = (
      <div style={{ display: "inline-block", marginTop: 8 }}>
        <Sigil
          seed={
            character?.iconSeedToken?.seed ||
            character?.iconSeed ||
            character?.id
          }
          gen={character?.iconSeedToken?.gen || "xenophon"}
          style={{ width: d }}
        />
      </div>
    );
  }
  if (!message.author.bot && message.author.photoURL) {
    img = (
      <img
        alt=""
        src={message.author.photoURL}
        width={d}
        height={d}
        style={{ marginBottom: "-7px" }}
      />
    );
  }
  return (
    <Card
      sx={{ p: 1, m: 1 }}
      style={{ ...style, backgroundColor }}
      className="message-card animated-fade-in-from-left"
    >
      <div className="character-chat-icon">{img}</div>
      <span style={authorStyle}>{message.author.displayName} </span>
      <span className="faint">{fromNow(message.created)}</span>
      <Tooltip title="Credit Cost">
        <div className="credit-cost-chip">{message.creditCost || 0}</div>
      </Tooltip>
      <Tooltip title="Objective Score">
        <div className="objective-score-chip">
          {message.objectiveScore || 0}
        </div>
      </Tooltip>
      <div className="accept-answer-chip">
        <Tooltip title="Accept this for current objective">
          <IconButton onClick={() => acceptAnswerForPlan(message.id)}>
            <ThumbUpIcon />
          </IconButton>
        </Tooltip>
      </div>
      <ReactMarkdown className="react-markdown-code-block">
        {message.text}
      </ReactMarkdown>
      <div className="message-card-chips">
        <div className="edit-chip" onClick={() => setExpanded(exp => !exp)}>
          EDIT
        </div>
        <div style={{ display: expanded ? "block" : "none" }}>
          {deleteMessage && (
            <IconButton
              onClick={() => {
                deleteMessage(message.id);
                setExpanded(false);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Tf
            fullWidth
            multiline
            rows={4}
            // slide up and down when expanded from height = 0 to height = auto
            InputProps={{
              style: { backgroundColor: "#ffffff", marginTop: "4px" },
            }}
            placeholder="Edit message..."
            value={message.text}
            onChange={e => {
              updateMessage(message.id, { text: e.target.value });
            }}
          />
        </div>
      </div>
    </Card>
  );
}

function MessagesArea({
  messages = [],
  metaMessages = [],
  updateMessage = () => {},
  deleteMessage = () => {},
  characters = [],
  messagingInProgress = false,
  acceptAnswerForPlan = () => {},
}) {
  // show the bouncing elipsis when the last message is from a human
  let cards = messages.concat(metaMessages);
  cards = cards.sort((a, b) => a?.created?.seconds - b?.created?.seconds);
  return (
    <>
      {cards.map((message, index) => {
        let delay = index * 0.1 + "s";
        if (index === cards.length - 1) delay = "0s";
        const style = { "--delay": delay };
        if (message.type === "META" || message.type === "SYSTEM") {
          return (
            <MetaMessageCard
              key={message.id}
              metaMessage={message}
              style={style}
            />
          );
        }
        const character = characters.find(c => c.id === message?.author?.id);
        return (
          <MessageCard
            key={message.id}
            message={message}
            updateMessage={updateMessage}
            deleteMessage={deleteMessage}
            character={character}
            acceptAnswerForPlan={acceptAnswerForPlan}
            style={style}
          />
        );
      })}
      {messagingInProgress && <BouncingElipsis />}
    </>
  );
}

function NewStageDirectionCard({ createStageDirection }) {
  const [stageDirection, setStageDirection] = useState("");
  return (
    <Card sx={{ p: 2, m: 2 }}>
      <TextField
        fullWidth
        multiline
        rows={4}
        placeholder="Add a stage direction..."
        value={stageDirection}
        onChange={e => {
          setStageDirection(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          createStageDirection(stageDirection);
          setStageDirection("");
        }}
      >
        Submit
      </Button>
    </Card>
  );
}

function StageDirectionsArea({
  stageDirections = [],
  characterStageDirections = [],
  createStageDirection,
  deleteStageDirection,
  editStageDirection,
}) {
  const [text, setText] = useState("");
  return (
    <div className="chat-stage-directions-area">
      <h3>Chat Specific Stage Directions</h3>
      <NewStageDirectionCard createStageDirection={createStageDirection} />
      {stageDirections.map(stageDirection => (
        <Card key={stageDirection.id} sx={{ p: 2, m: 2 }}>
          <IconButton
            onClick={() => deleteStageDirection(stageDirection.id)}
            style={{ float: "right" }}
          >
            <DeleteIcon />
          </IconButton>
          <p className="properly-formatted-text">{stageDirection.text}</p>
          <Accordion>
            <AccordionSummary>edit</AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Add a stage direction..."
                value={text || stageDirection.text}
                onChange={e => {
                  setText(e.target.value);
                }}
              />
              {text.length > 0 && (
                <Button
                  onClick={() => {
                    editStageDirection(stageDirection.id, text);
                  }}
                >
                  Update Stage Direction
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </div>
  );
}

function Data() {
  useLogPageView("Chat");
  const [record, setRecord] = useState(null);
  const [characterId, setCharacterId] = useState("");
  const [character, setCharacter] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [plans, setPlans] = useState([]);
  const [messages, setMessages] = useState([]);
  const [metaMessages, setMetaMessages] = useState([]);
  const [messageLimit, setMessageLimit] = useState(8);
  const [credits, setCredits] = useState({});
  const [rightPanelOpen] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [mostRecentPrompt, setMostRecentPrompt] = useState(null);
  // get the user
  const [user, loading] = useAuthState(auth);
  const { activeProjectId = "" } = useEphemera();
  const messagesAreaRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    // check if the user is on a mobile device
    const isMobile = window.innerWidth < 600;
    setIsMobile(isMobile);
  }, []);

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages"
    );
    const q = query(
      collectionRef,
      orderBy("created", "desc"),
      limit(messageLimit)
    );
    const unsubscribe = onSnapshot(q, snap => {
      // reverse the order of the documents
      let data = snap.docs.map(doc => doc.data()).reverse();
      if (!data) return;
      setMessages(data);
      messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
    });
    return unsubscribe;
  }, [id, activeProjectId, record, messageLimit]);

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "meta_messages"
    );
    const q = query(
      collectionRef,
      orderBy("created", "desc"),
      limit(messageLimit)
    );
    const unsubscribe = onSnapshot(q, snap => {
      // reverse the order of the documents
      let data = snap.docs.map(doc => doc.data()).reverse();
      if (!data) return;
      setMetaMessages(data);
      messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
    });
    return unsubscribe;
  }, [id, activeProjectId, record, messageLimit]);

  useEffect(() => {
    // get the chat document
    if (!activeProjectId || !id) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
      setCharacterId(data.characterId);
      setDisplayName(data.displayName);
    });
    return unsubscribe;
  }, [id, activeProjectId, loading]);

  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "prompts"
    );
    const q = query(colRef, orderBy("created", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, snap => {
      const prompts = [];
      snap.docs.forEach(doc => prompts.push(doc.data()));
      if (prompts.length > 0) setMostRecentPrompt(prompts[0]);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the character
  useEffect(() => {
    if (!characterId) return;
    if (!activeProjectId) return;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "characters",
      characterId
    );
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setCharacter(data);
    });
    return unsubscribe;
  }, [characterId, activeProjectId, loading]);

  // get the credits for the project
  useEffect(() => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, "meta", "credits");
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return setCredits({ amount: 0 });
      setCredits(data);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the characters, all of them...
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "characters");
    const unsubscribe = onSnapshot(colRef, snap => {
      const characters = [];
      snap.docs.forEach(doc => characters.push(doc.data()));
      setCharacters(characters);
    });
    return unsubscribe;
  }, [activeProjectId]);

  const updateRecord = update => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    updateDoc(docRef, { ...update, lastUpdated: serverTimestamp() });
  };

  // get all the plans
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "plans");
    const unsubscribe = onSnapshot(colRef, snap => {
      const plans = [];
      snap.docs.forEach(doc => plans.push(doc.data()));
      setPlans(plans);
    });
    return unsubscribe;
  }, [activeProjectId]);

  const updateMessage = (messageId, update) => {
    if (!activeProjectId) return;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages",
      messageId
    );
    updateDoc(docRef, { ...update, lastUpdated: serverTimestamp() });
  };

  const duplicateChat = async () => {
    // duplicates the last 10 messages
    if (!activeProjectId) return;
    const colRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages"
    );
    const q = query(colRef, orderBy("created", "desc"), limit(10));
    const snap = await getDocs(q);
    const messages = [];
    snap.docs.forEach(doc => {
      const message = doc.data();
      message.id = uuid();
      message.duplication = true;
      messages.push(message);
    });
    const newId = uuid();
    const newChat = {
      ...record,
      id: newId,
      displayName: record.displayName + " (copy)",
    };
    if (record.id === newChat.id) return console.log("duplicate chat failed");
    const chatDocRef = doc(db, "projects", activeProjectId, COLLECTION, newId);
    await setDoc(chatDocRef, newChat);
    // the messages are each a document in a subcollection
    messages.forEach(async message => {
      const messageDocRef = doc(
        db,
        "projects",
        activeProjectId,
        COLLECTION,
        newId,
        "messages",
        message.id
      );
      await setDoc(messageDocRef, message);
    });
    history.push(`/chats/${newId}`);
    // then scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const deleteMessage = messageId => {
    if (!activeProjectId) return;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages",
      messageId
    );
    deleteDoc(docRef);
  };

  const deleteStageDirection = async stageDirectionId => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirections = record.stageDirections || [];
    const newStageDirections = stageDirections.filter(
      stageDirection => stageDirection.id !== stageDirectionId
    );
    return await updateDoc(docRef, { stageDirections: newStageDirections });
  };

  const editStageDirection = async (stageDirectionId, text) => {
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirections = record.stageDirections || [];
    const newStageDirections = stageDirections.map(stageDirection => {
      if (stageDirection.id === stageDirectionId) {
        stageDirection.text = text;
      }
      return stageDirection;
    });
    return await updateDoc(docRef, { stageDirections: newStageDirections });
  };

  const createStageDirection = async text => {
    log("createChatStageDirection");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const stageDirection = StageDirection(text);
    const stageDirections = record.stageDirections || [];
    await updateDoc(docRef, {
      stageDirections: [...stageDirections, stageDirection],
    });
    // scroll the messages-area to the bottom
    messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
  };

  const createMessage = async text => {
    if (!activeProjectId) return;
    if (!user) return;
    if (!record) return;
    log("createMessage");
    const message = Message(text);
    const denormalizedUserData = {
      id: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL,
      bot: false,
    };
    message.author = denormalizedUserData;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages",
      message.id
    );
    await setDoc(docRef, message);
    // scroll the messages-area to the bottom
    messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    log("deleteChat");
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const acceptAnswerForPlan = async messageId => {
    // okay clicking the thumbs up button will accept the answer for the plan
    // so we need to update the current task in the current plan with the answer
    // into the tasks's result property
    // then get a new eligible task from the plan
    // and update the current task in the chat with the new task
    // and if there are no eligible tasks left, then we need to make sure
    // the toggle for allowing bots to talk to one another is turned off
    // that variable is named groupChat on the chat object
    // first get the chat
    const chat = record;
    if (!chat) return console.log("no chat");
    if (!chat.planId) return console.log("no planId");
    if (!chat.currentTaskId) return console.log("no currentTaskId");
    // get the plan
    const plan = plans.find(plan => plan.id === chat.planId);
    if (!plan) return console.log("no plan");
    // get the current task
    const currentTask = find(plan.tasks[0], t => t.id === chat.currentTaskId);
    if (!currentTask) return console.log("no currentTask");
    // get the message
    const message = messages.find(m => m.id === messageId);
    if (!message) return console.log("no message");
    // at this point we have everything we need
    // so we can update the current task with the answer
    currentTask.result = message.text;
    currentTask.status = "COMPLETE";
    // and get a new eligible task from the plan
    const newTask = pick(
      findAll(plan.tasks[0], t => !t.result && t.status === "READY")
    );
    if (!newTask) {
      // if there are no eligible tasks left, then we need to make sure
      // the toggle for allowing bots to talk to one another is turned off
      // that variable is named groupChat on the chat object
      chat.groupChat = false;
    }
    // now we can update the current task in the chat with the new task
    chat.currentTaskId = newTask ? newTask.id : "";
    chat.objective = newTask ? newTask.description : "";
    // and update the chat
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, chat);
    // and update the plan
    const planDocRef = doc(db, "projects", activeProjectId, "plans", plan.id);
    await updateDoc(planDocRef, plan);
  };

  if (!record) return <div ref={messagesAreaRef}>Loading...</div>;

  // flex container column blah blah blah: stack them
  const mobileMessagesAreaContainer = {
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const mobileMessagesAreaStyle = {
    overflowY: "scroll",
    flex: 1,
    paddingBottom: 120,
  };

  const renderMobileVersion = () => {
    let cards = messages.concat(metaMessages);
    cards = cards.sort((a, b) => a?.created?.seconds - b?.created?.seconds);
    return (
      <>
        <div style={mobileMessagesAreaContainer}>
          <div style={mobileMessagesAreaStyle} ref={messagesAreaRef}>
            {cards.map(message => {
              if (message.type === "META" || message.type === "SYSTEM") {
                return (
                  <MetaMessageCard key={message.id} metaMessage={message} />
                );
              }
              const character = characters.find(
                c => c.id === message.author.id
              );
              return (
                <MessageCard
                  key={message.id}
                  message={message}
                  updateMessage={updateMessage}
                  deleteMessage={deleteMessage}
                  character={character}
                  acceptAnswerForPlan={acceptAnswerForPlan}
                />
              );
            })}
          </div>
          <NewMessageBottomBar
            createMessage={createMessage}
            creditsRemaining={credits?.amount || 0}
          />
        </div>
        <Drawer anchor="right" open={rightPanelOpen}>
          <div style={{ width: 300 }}>
            <div>
              <h1 className="title-with-supertext">
                {COLLECTION_DISPLAY_NAME}: {record.displayName}
              </h1>
              {character && character.photoId ? (
                <CharacterImageContainer imageId={character.photoId} />
              ) : null}
              {character ? (
                <p>
                  with{" "}
                  <Link to={"/characters/" + character.id}>
                    {character.displayName}
                  </Link>
                </p>
              ) : null}
              <Tf
                type="text"
                fullWidth
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
              />
            </div>
            <StageDirectionsArea
              stageDirections={record.stageDirections}
              characterStageDirections={character?.stageDirections}
              createStageDirection={createStageDirection}
              deleteStageDirection={deleteStageDirection}
              editStageDirection={editStageDirection}
            />
            <Card variant="outlined" className="padded DeletionArea">
              <h3> Danger Zone</h3>
              <Button
                variant="contained"
                color="secondary"
                onClick={deleteRecord}
              >
                Delete {record.displayName}
              </Button>
            </Card>
          </div>
        </Drawer>
      </>
    );
  };

  const toggleGroupChat = () => {
    log("toggleGroupChat");
    updateRecord({ groupChat: !record.groupChat });
  };

  const createElicitation = async characterId => {
    log("createElicitation");
    const elicitation = Elicitation(characterId);
    elicitation.userId = user.uid;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "elicitations",
      elicitation.id
    );
    return await setDoc(docRef, elicitation);
  };

  const createSalutationMessage = async character => {
    const message = Message(character.salutation || "Hello.");
    const denormalizedAuthorData = {
      id: character.id,
      displayName: character.displayName,
      photoURL: null,
      bot: true,
    };
    message.author = denormalizedAuthorData;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      id,
      "messages",
      message.id
    );
    await setDoc(docRef, message);
    // scroll the messages-area to the bottom
    messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
  };

  const pickTask = async () => {
    // get the plan from the list of plans by record.planId
    if (!record) return;
    if (!plans || !plans.length) return;
    if (!record.planId) return;
    const plan = plans.find(p => p.id === record.planId);
    const rootTask = plan.tasks[0];
    let task = rootTask;
    let candidates = findAll(rootTask, t => {
      return t.status === "READY" && t.subtasks.length === 0;
    });
    if (candidates.length) task = pick(candidates);
    // to start just pick the first one
    const currentTaskId = task.id;
    const objective = task.description;
    await updateRecord({ currentTaskId, objective });
  };

  const renderGroupChatOptions = () => {
    return (
      <div>
        <h3>Add Characters to the Chat</h3>
        <Select
          fullWidth
          multiple
          value={record.characters || []}
          onChange={e => {
            const priorCharacters = record.characters || [];
            updateRecord({ characters: e.target.value });
            // get the new characters
            const newCharacters = e.target.value.filter(
              c => !priorCharacters.includes(c)
            );
            newCharacters.forEach(async characterId => {
              const character = characters.find(c => c.id === characterId);
              await createSalutationMessage(character);
            });
          }}
        >
          {characters.map(character => {
            return (
              <MenuItem key={character.id} value={character.id}>
                {character.displayName}
              </MenuItem>
            );
          })}
        </Select>
        <div style={{ height: 20 }} />
        <Tf
          fullWidth
          label="Objective"
          value={record?.objective || ""}
          onChange={e => updateRecord({ objective: e.target.value })}
        />
        <Select
          fullWidth
          value={record?.planId || ""}
          onChange={e => updateRecord({ planId: e.target.value })}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {plans.map(plan => {
            return (
              <MenuItem key={plan.id} value={plan.id}>
                {plan.displayName}
              </MenuItem>
            );
          })}
        </Select>
        <Button onClick={pickTask}>Pick a Different Task</Button>
        <h3>
          <Switch checked={record.groupChat} onChange={toggleGroupChat} />
          Allow Characters to Chat amongst themselves until the objective is
          reached
        </h3>
      </div>
    );
  };

  const renderActiveCharactersImages = () => {
    if (!record.characters) return null;
    let d = ((window.innerWidth - 140) * 0.45) / record.characters.length + 1;
    if (d > 256) d = 256;
    const style = {
      display: "inline-block",
      margin: "8px 8px 0 0",
      width: d,
    };

    return (
      <div>
        {record.characters.map(characterId => {
          const character = characters.find(c => c.id === characterId);
          if (!character) return null;
          return (
            <div style={style} key={character.id}>
              <Tooltip title={character.displayName}>
                <Link to={"/characters/" + character.id}>
                  {character.photoId ? (
                    <CharacterImage
                      imageId={character.photoId}
                      dimensions={d}
                    />
                  ) : (
                    <Sigil
                      seed={
                        character.iconSeedToken?.seed ||
                        character.iconSeed ||
                        character.id
                      }
                      gen={character.iconSeedToken?.gen || "xenophon"}
                      style={{ width: d }}
                    />
                  )}
                </Link>
              </Tooltip>
              <Button
                fullWidth
                variant="contained"
                style={{ background: "#ccc", color: "#000", height: 24 }}
                onClick={() => createElicitation(character.id)}
              >
                Elicit
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDesktopVersion = () => {
    return (
      <Box sx={{ flexGrow: 1 }} className="padded">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="messages-area" ref={messagesAreaRef}>
              <Button onClick={() => setMessageLimit(lim => lim + 8)}>
                Load More
              </Button>
              <MessagesArea
                messages={messages}
                metaMessages={metaMessages}
                updateMessage={updateMessage}
                deleteMessage={deleteMessage}
                characters={characters}
                messagingInProgress={record?.messagingInProgress || false}
                acceptAnswerForPlan={acceptAnswerForPlan}
              />
            </div>
            {credits.amount > 0 ? (
              <NewMessageCard createMessage={createMessage} />
            ) : (
              <p>
                <Link to="/settings">Buy more credits</Link> to send more
                messages.
              </p>
            )}
            <p
              className="credits-sneak"
              style={{ background: credits.amount < 500 ? "#ffcccc" : null }}
            >
              Credits Remaining: {credits.amount}
            </p>
          </Grid>
          <Grid item xs={6}>
            <div>
              <div>
                <Tf
                  type="text"
                  fullWidth
                  label="Chat Name"
                  value={record.displayName}
                  InputProps={{ style: { fontSize: "3em" } }}
                  onChange={e => updateRecord({ displayName: e.target.value })}
                />
                {renderActiveCharactersImages()}
              </div>
            </div>
            <div className="clear-floats" />
            <div style={{ height: 20 }} />
            {renderGroupChatOptions()}
            <StageDirectionsArea
              stageDirections={record.stageDirections}
              characterStageDirections={character?.stageDirections}
              createStageDirection={createStageDirection}
              deleteStageDirection={deleteStageDirection}
              editStageDirection={editStageDirection}
            />
            <div style={{ height: 20 }} />
            <Accordion>
              <AccordionSummary>Most Recent Prompt</AccordionSummary>
              <AccordionDetails>
                <div className="recent-message-details">
                  ~{(mostRecentPrompt?.text?.split(" ").length * 0.8) | 0}{" "}
                  tokens
                </div>
                <p className="message-card-text">{mostRecentPrompt?.text}</p>
              </AccordionDetails>
            </Accordion>
            <div style={{ height: 20 }} />
            <Button
              sx={{ background: "#333", color: "#fff" }}
              onClick={duplicateChat}
            >
              Duplicate Chat
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className="padded DeletionArea">
              <h3> Danger Zone</h3>
              <Button
                variant="contained"
                color="secondary"
                onClick={deleteRecord}
              >
                Delete {record.displayName}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // render the mobile version or the desktop version depending on the screen size
  if (isMobile) return renderMobileVersion();
  return <>{renderDesktopVersion()}</>;
}

const Page = () => {
  return (
    <MinorFrame upperLeftNav>
      <Data />
    </MinorFrame>
  );
};

export default Page;
