import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Sigil from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import Tf from "../../components/text-field/";
import { db, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { IconSeedToken, UpdatePackageRequest } from "../../models";
import { COLLECTION } from "./constants";
import "./style.css";

const defaultIconSeedTokens = [
  IconSeedToken(),
  IconSeedToken(),
  IconSeedToken(),
  IconSeedToken(),
];

function Data() {
  useLogPageView("Package");
  const [record, setRecord] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [sources, setSources] = useState(null);
  const [services, setServices] = useState(null);
  const [studios, setStudios] = useState(null);
  const [plans, setPlans] = useState(null);
  const [chats, setChats] = useState(null);
  const [iconSeedTokens, setIconSeedTokens] = useState(defaultIconSeedTokens);
  const history = useHistory();
  const { activeProjectId = "" } = useEphemera();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      let data = snap.data();
      if (!data) return;
      setRecord(data);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the characters
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "characters");
    const unsubscribe = onSnapshot(colRef, snap => {
      const characters = [];
      snap.forEach(doc => {
        characters.push(doc.data());
      });
      setCharacters(characters);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the templates
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "templates");
    const unsubscribe = onSnapshot(colRef, snap => {
      const templates = [];
      snap.forEach(doc => {
        templates.push(doc.data());
      });
      setTemplates(templates);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the sources
  useEffect(() => {
    if (!id) return;
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "sources");
    const unsubscribe = onSnapshot(colRef, snap => {
      const sources = [];
      snap.forEach(doc => {
        sources.push(doc.data());
      });
      setSources(sources);
    });
    return unsubscribe;
  }, [id, activeProjectId]);

  // get the services
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "services");
    const unsubscribe = onSnapshot(colRef, snap => {
      const services = [];
      snap.docs.forEach(doc => services.push(doc.data()));
      setServices(services);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the studios
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "studios");
    const unsubscribe = onSnapshot(colRef, snap => {
      const studios = [];
      snap.docs.forEach(doc => studios.push(doc.data()));
      setStudios(studios);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the plans
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "plans");
    const unsubscribe = onSnapshot(colRef, snap => {
      const plans = [];
      snap.docs.forEach(doc => plans.push(doc.data()));
      setPlans(plans);
    });
    return unsubscribe;
  }, [activeProjectId]);

  // get the chats
  useEffect(() => {
    if (!activeProjectId) return;
    const colRef = collection(db, "projects", activeProjectId, "chats");
    const unsubscribe = onSnapshot(colRef, snap => {
      const chats = [];
      snap.forEach(doc => {
        chats.push(doc.data());
      });
      setChats(chats);
    });
    return unsubscribe;
  }, [activeProjectId]);

  const moreIconSeedTokens = () => {
    setIconSeedTokens([
      ...iconSeedTokens,
      IconSeedToken(),
      IconSeedToken(),
      IconSeedToken(),
    ]);
  };

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    await deleteDoc(doc(db, "projects", activeProjectId, COLLECTION, id));
    history.push("/" + COLLECTION);
  };

  const updateRecord = async update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    await updateDoc(docRef, { ...update, lastUpdated: Timestamp.now() });
  };

  const makePublic = async () => {
    if (!activeProjectId) return;
    const rec = UpdatePackageRequest();
    rec.packageId = id;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "update_package_requests",
      rec.id
    );
    await setDoc(docRef, rec);
  };

  const setIconSeedToken = async iconSeedToken => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const previousIconSeedToken = record.iconSeedToken || {
      id: record.id,
      seed: record.id,
      gen: "xenophon",
    };
    // append the previous icon seed to the list of icon seeds
    const newIconSeedTokens = [...iconSeedTokens, previousIconSeedToken];
    // remove duplicates
    const uniqueIconSeedTokens = [...new Set(newIconSeedTokens)];
    // remove the current icon seed
    setIconSeedTokens(
      uniqueIconSeedTokens.filter(t => t.seed !== iconSeedToken.seed)
    );
    await updateDoc(docRef, { iconSeedToken }, { merge: true });
  };

  if (!record) return null;

  const icons = [];
  iconSeedTokens.forEach(t => {
    icons.push(
      <span
        onClick={() => setIconSeedToken(t)}
        style={{ cursor: "pointer" }}
        key={t.seed}
      >
        <Sigil seed={t.seed} style={{ width: 64 }} gen={t.gen} />
      </span>
    );
  });

  let seed = record.iconSeed || record.id;
  if (record.iconSeedToken) seed = record.iconSeedToken.seed;
  let gen = "xenophon";
  if (record.iconSeedToken) gen = record.iconSeedToken.gen;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <div style={{ textAlign: "center" }}>
            <Sigil seed={seed} style={{ width: 256 }} gen={gen} />
          </div>
          <div>
            <Tf
              label="Package Name"
              InputProps={{ style: { fontSize: "2em" } }}
              value={record?.displayName}
              onChange={e => updateRecord({ displayName: e.target.value })}
              fullWidth
            />
            <div style={{ height: 24 }} />
            <Tf
              label="Package Description"
              value={record?.description}
              onChange={e => updateRecord({ description: e.target.value })}
              multiline
              rows={4}
              fullWidth
            />
            <h3>Change Profile Icon</h3>
            {icons}
            <p
              onClick={() => moreIconSeedTokens()}
              style={{ cursor: "pointer" }}
            >
              more icons...
            </p>
            {record?.sourceProjectId === activeProjectId && (
              <Button
                variant="contained"
                sx={{ background: "yellow", color: "black" }}
                fullWidth
                onClick={makePublic}
              >
                Make Public
              </Button>
            )}
          </div>
        </Grid>
        <Grid item lg={9} xs={12}>
          <div>
            <h3>Add Characters</h3>
            <Select
              fullWidth
              multiple
              value={record?.characterIds || []}
              onChange={e => {
                updateRecord({ characterIds: e.target.value });
              }}
            >
              {characters?.map(character => {
                return (
                  <MenuItem key={character.id} value={character.id}>
                    {character.displayName}
                  </MenuItem>
                );
              })}
            </Select>
            <div style={{ height: 20 }} />
            <h3>Add Services</h3>
            <Select
              fullWidth
              multiple
              value={record?.serviceIds || []}
              onChange={e => {
                updateRecord({ serviceIds: e.target.value });
              }}
            >
              {services?.map(service => {
                return (
                  <MenuItem key={service.id} value={service.id}>
                    {service.displayName}
                  </MenuItem>
                );
              })}
            </Select>
            <div style={{ height: 20 }} />
            <h3>Add Sources</h3>
            <Select
              fullWidth
              placeholder="Select Sources"
              value={record.sourceIds || []}
              multiple
              onChange={e => {
                updateRecord({ sourceIds: e.target.value });
              }}
            >
              {sources &&
                sources.map(s => {
                  return (
                    <MenuItem key={s.id} value={s.id}>
                      {s.displayName}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          <h3>Add Templates</h3>
          <Select
            fullWidth
            placeholder="Select a Template"
            value={record.templateIds || []}
            multiple
            onChange={e => {
              updateRecord({ templateIds: e.target.value });
            }}
          >
            <MenuItem value="">None</MenuItem>
            {templates.map(template => {
              return (
                <MenuItem key={template.id} value={template.id}>
                  {template.displayName}
                </MenuItem>
              );
            })}
          </Select>
          <h3>Add Studios</h3>
          <Select
            fullWidth
            placeholder="Select Studios"
            value={record.studioIds || []}
            multiple
            onChange={e => {
              updateRecord({ studioIds: e.target.value });
            }}
          >
            {studios &&
              studios.map(s => {
                return (
                  <MenuItem key={s.id} value={s.id}>
                    {s.displayName}
                  </MenuItem>
                );
              })}
          </Select>
          <h3>Add Plans</h3>
          <Select
            fullWidth
            placeholder="Select Plans"
            value={record.planIds || []}
            multiple
            onChange={e => {
              updateRecord({ planIds: e.target.value });
            }}
          >
            {plans &&
              plans.map(p => {
                return (
                  <MenuItem key={p.id} value={p.id}>
                    {p.displayName}
                  </MenuItem>
                );
              })}
          </Select>
          <h3>Add Chats</h3>
          <Select
            fullWidth
            placeholder="Select Chats"
            value={record.chatIds || []}
            multiple
            onChange={e => {
              updateRecord({ chatIds: e.target.value });
            }}
          >
            {chats &&
              chats.map(c => {
                return (
                  <MenuItem key={c.id} value={c.id}>
                    {c.displayName}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" className="padded DeletionArea">
            <h3> Danger Zone</h3>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteRecord}
            >
              Delete {record.displayName}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
