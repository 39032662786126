import {
  doc,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState, useRef } from "react";
import { db } from "../db";

const validCollectionPath = (collectionPathString = "") => {
  const collectionPath = collectionPathString.split("/");
  if (collectionPath.length % 2 === 0) return false;
  for (let i = 0; i < collectionPath.length; i++) {
    if (!collectionPath[i]) return false;
  }
  return true;
};

export const useFirestorePagination = (
  collectionPath = [],
  initialLimit = 12,
  orderedBy = "created",
  orderDirection = "desc" // asc or desc
) => {
  const [data, setData] = useState([]);
  const [lim, setLim] = useState(initialLimit);
  const [hasMore, setHasMore] = useState(true);
  const unsubscribeRef = useRef(null); // keep track of the listener for clean-up

  const fetchPage = useCallback(
    (startAfterDoc = null) => {
      if (!validCollectionPath(collectionPath)) return null;
      const colRef = collection(db, collectionPath);
      let paginatedQueryRef = query(
        colRef,
        orderBy(orderedBy, orderDirection),
        limit(lim)
      );
      if (startAfterDoc) {
        paginatedQueryRef = query(
          colRef,
          orderBy(orderedBy, orderDirection),
          startAfter(startAfterDoc),
          limit(lim)
        );
      }
      // stop listening to the previous query
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      unsubscribeRef.current = onSnapshot(paginatedQueryRef, snap => {
        if (snap.docs.length > 0) {
          const newData = snap.docs.map(doc => doc.data());
          if (newData.length < lim) setHasMore(false);
          // why did I even write this??
          //setData(prevData => [...prevData, ...newData]);
          setData(newData);
        } else {
          setHasMore(false);
        }
      });
    },
    [lim, orderedBy, collectionPath]
  );

  useEffect(() => {
    fetchPage();
    // clean up function to stop listening
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [fetchPage]);

  const loadMore = () => {
    if (hasMore) setLim(prevLim => prevLim + initialLimit);
  };

  return [data, loadMore, hasMore];
};

export const useFirestorePaginationWithWhere = (
  collectionPath = [],
  initialLimit = 12,
  orderedBy = "created",
  wherePath = []
) => {
  const [data, setData] = useState([]);
  const [lim, setLim] = useState(initialLimit);
  const [hasMore, setHasMore] = useState(true);
  const unsubscribeRef = useRef(null); // keep track of the listener for clean-up

  const fetchPage = useCallback(
    (startAfterDoc = null) => {
      if (!validCollectionPath(collectionPath)) return null;
      const colRef = collection(db, collectionPath);
      let paginatedQueryRef = query(
        colRef,
        orderBy(orderedBy, "desc"),
        where(...wherePath),
        limit(lim)
      );
      if (startAfterDoc) {
        paginatedQueryRef = query(
          colRef,
          orderBy(orderedBy, "desc"),
          where(...wherePath),
          startAfter(startAfterDoc),
          limit(lim)
        );
      }
      // stop listening to the previous query
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      unsubscribeRef.current = onSnapshot(paginatedQueryRef, snap => {
        if (snap.docs.length > 0) {
          const newData = snap.docs.map(doc => doc.data());
          if (newData.length < lim) setHasMore(false);
          setData(newData);
        } else {
          setHasMore(false);
        }
      });
    },
    [lim, orderedBy, collectionPath, wherePath]
  );

  useEffect(() => {
    fetchPage();
    // clean up function to stop listening
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [fetchPage]);

  const loadMore = () => {
    if (hasMore) setLim(prevLim => prevLim + initialLimit);
  };

  const deleteById = id => {
    if (!id) return null;
    // delete the document with the given id from the collection and
    // also remove it from the data state
    setData(prevData => prevData.filter(item => item.id !== id));
    const docRef = doc(db, collectionPath, id);
    return deleteDoc(docRef);
  };

  return [data, loadMore, hasMore, deleteById];
};
